<template>
    <Head :title="title"/>
    <Banner/>

    <NavigationBar/>

    <div class="bg-white"
    :class="{
        'pt-20': !isLandingPage()
    }"
    >

        <slot/>

        <Footer/>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import {Dialog, DialogPanel} from '@headlessui/vue'
import {Bars3Icon, XMarkIcon} from '@heroicons/vue/24/outline'
import {Link, router, usePage} from "@inertiajs/vue3";

import {Head} from "@inertiajs/vue3";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import Banner from "@/Components/Banner.vue";
import StartTrialBusinessButton from "@/Components/Marketing/StartTrialBusinessButton.vue";
import NavigationBar from "@/Layouts/Partials/NavigationBar.vue";
import Footer from "@/Layouts/Partials/Footer.vue";

const navigation = [
    {name: 'Home', href: route('index'), current: route().current('index')},
    {name: 'Features', href: '#', scrollTo: 'features'},
    {name: 'Pricing', href: '#', scrollTo: 'pricing'},
    {name: 'Mission', href: route('post', {slug: 'blog/our-mission-behind-greetmate'})},
    {name: 'Blog', href: route('blog'), current: route().current('blog') || route().current('post')},
]

const navigateToItem = async (item) => {
    mobileMenuOpen.value = false;
    if (item.scrollTo) {
        if (isLandingPage()) {
            document.getElementById(item.scrollTo).scrollIntoView({behavior: 'smooth'})
        } else {
            await router.visit(route('index'), {method: 'get', replace: true})

            setTimeout(() => {
                document.getElementById(item.scrollTo).scrollIntoView({behavior: 'smooth'})
            }, 150)

        }
    } else {
        router.visit(item.href, {method: 'get'});
    }
}


const mobileMenuOpen = ref(false)

const isLandingPage = () => {
    return route().current('index') || route().current('landing') || route().current('testimonials')
}


defineProps({
    title: String,
});

</script>
