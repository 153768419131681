<template>
    <!-- Footer -->
    <footer class="mt-32 bg-gray-900 sm:mt-56" aria-labelledby="footer-heading">
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="mx-auto max-w-7xl px-6 pb-8 sm:pt-16 lg:px-8 lg:pt-16">
            <div class="mt-6 pt-10 lg:mt-16 grid md:grid-cols-5 gap-8 xl:mt-0 col-span-2">
                <div class=""
                     v-for="(section, key) in footerNavigation"
                >
                    <div class="mt-0 md:mt-0">
                        <h3 class="text-sm font-semibold leading-6 text-white">
                            {{ section.label }}
                        </h3>
                        <ul role="list" class="mt-6 space-y-4">
                            <li v-for="item in section.links" :key="key">
                                <span v-if="!item.href"
                                      class="text-sm leading-6 text-gray-300 "
                                >
                                    {{ item.name }}
                                </span>
                                <Link
                                    v-else
                                    :href="item.href"
                                    @click="navigateToItem(item)"
                                    class="cursor-pointer text-sm leading-6 text-gray-300 hover:text-white">{{
                                        item.name
                                    }}
                                </Link>
                            </li>
                        </ul>

                    </div>
                </div>

                <div class="mt-0 md:mt-0 space-y-4">
                    <h3 class="text-sm font-semibold leading-6 text-white">Contact:</h3>

                    <p class="text-white">
                        <a href="tel:+19495654036" class="text-sm">
                            (949) 565-4036
                        </a>
                    </p>
                    <p class="text-white">
                        <a @click="linkTo_UnCryptMailto('nbjmup;tbmftAhsffunbuf/bj');"
                           class="cursor-pointer hover:underline text-sm">sales@greetmate.ai</a>
                    </p>

                </div>
            </div>

            <div class="mt-12 flex max-lg:flex-col gap-8 justify-between w-full">
                <ApplicationLogo class="w-40"/>

                <div class="text-white flex gap-2">
                    <a href="https://www.linkedin.com/company/greetmate-inc" target="_blank">
                        <MdiIcon :icon="mdiLinkedin" class="size-6"/>
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61564822569525" target="_blank">
                        <MdiIcon :icon="mdiFacebook" class="size-6"/>
                    </a>
                    <a href="https://www.instagram.com/greetmate.ai/" target="_blank">
                        <MdiIcon :icon="mdiInstagram" class="size-6"/>
                    </a>
                    <a href="https://www.youtube.com/channel/UCmOIhwvDnmPKZ7eLpHbSzDA" target="_blank">
                        <MdiIcon :icon="mdiYoutube" class="size-6"/>
                    </a>
                </div>
            </div>

            <div class="grid lg:grid-cols-2 mt-8 border-t border-white/10 pt-8 md:flex md:items-start md:justify-between">
                <div class="flex lg:space-x-6 max-lg:flex-wrap gap-2">
                    <Link :href="route('terms.show')" class="text-white text-xs">
                        Terms and Conditions
                    </Link>

                    <Link :href="route('terms.acceptable-use')" class="text-white text-xs">
                        Acceptable Use Policy
                    </Link>

                    <Link :href="route('terms.disclaimer')" class="text-white text-xs">
                        Disclaimer
                    </Link>

                    <Link :href="route('policy.show')" class="text-white text-xs">
                        Privacy Policy
                    </Link>

                    <a href="#" class="termly-display-preferences text-white text-xs ">Consent Preferences</a>
                </div>

                <p class="mt-8 text-xs leading-7 md:mt-0 text-white lg:text-right">
                    &copy;
                    {{ new Date().getFullYear() }} Greetmate Inc.

                    <p class="text-xs text-gray-500">
                        Made with ❤️ in Irvine, CA.
                    </p>
                </p>
            </div>

            <iframe src="https://status.greetmate.ai/badge?theme=dark" width="250" height="30" frameborder="0"
                    scrolling="no" style="color-scheme: normal" class="mt-4"></iframe>
        </div>
    </footer>


</template>
<script setup>
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import {router} from "@inertiajs/vue3";
import {Link} from '@inertiajs/vue3';
import MdiIcon from "@/Components/MdiIcon.vue";
import {mdiFacebook, mdiInstagram, mdiLinkedin, mdiYoutube} from "@mdi/js";

const footerNavigation = {
    integrations: {
        label: 'Integrations',
        links: [
            {name: 'Hubspot',},
            {name: 'Zapier', },
            {name: 'Cal.com',},
            {name: 'GoHighLevel',},
            {name: 'Twilio',},
        ]
    },
    company: {
        label: 'Company',
        links: [
            {name: 'Careers', href: route('post', {slug: 'coming-soon'}), current: route().current('register')},
            {name: 'Blog', href: route('blog'), current: route().current('blog')},
        ]
    },
    app: {
        label: 'App',
        links: [
            {name: 'Sign Up', href: route('register'), current: route().current('register')},
            {name: 'Start Free Trial', href: route('register'), current: route().current('register')},
            {name: 'Login', href: route('login'), current: route().current('login')},
        ]
    },
    resouces: {
        label: 'Resources',
        links: [
            {name: 'Docs', href: route('index')},
            {name: 'Affiliate', href: route('post', {slug: 'coming-soon'})},
            {name: 'Agency Program', href: route('post', {slug: 'coming-soon'})},
        ]
    },
    // compare: {
    //     label: 'Compare',
    //     links: [
    //         {name: 'Greetmate vs. Synthflow', href: route('index')},
    //         {name: 'Greetmate vs. Bland', href: route('index')},
    //         {name: 'Greetmate vs. Simpletalk', href: route('index')},
    //     ]
    // },
}


function UnCryptMailto(s) {
    var n = 0;
    var r = "";
    for (var i = 0; i < s.length; i++) {
        n = s.charCodeAt(i);
        if (n >= 8364) {
            n = 128;
        }
        r += String.fromCharCode(n - 1);
    }
    return r;
}

function linkTo_UnCryptMailto(s) {
    location.href = UnCryptMailto(s);
}

const isLandingPage = () => {
    return route().current('index') || route().current('landing') || route().current('testimonials')
}

const navigateToItem = async (item) => {
    if (item.scrollTo) {
        if (isLandingPage()) {
            document.getElementById(item.scrollTo).scrollIntoView({behavior: 'smooth'})
        } else {
            await router.visit(route('index'), {method: 'get', replace: true})

            setTimeout(() => {
                document.getElementById(item.scrollTo).scrollIntoView({behavior: 'smooth'})
            }, 150)

        }
    } else {
        if (item.href.includes('http') && !item.href.includes('greetmate.ai')) {
            window.open(item.href, '_blank');
            return;
        }

        router.visit(item.href, {method: 'get'});
    }
}
</script>
