<template>
    <header class="fixed top-0 w-full z-50 transition-all duration-300"
            :class="{
        'bg-white shadow-md': invert,
        'bg-white': !invert
    }"
    >
        <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div class="flex lg:flex-1">
                <Link :href="route('index')" class="-m-1.5 p-1.5">
                    <span class="sr-only">Greetmate.ai</span>
                    <ApplicationLogo
                        :color="invert ? 'black' : 'black'"
                    />
                </Link>
            </div>
            <div class="flex lg:hidden">
                <button type="button"
                        class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        @click="mobileMenuOpen = true">
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon class="size-6" aria-hidden="true"/>
                </button>
            </div>
            <PopoverGroup class="hidden lg:flex md:gap-x-6 xl:gap-x-10">
                <Popover class="relative" v-slot="{ open, close }">
                    <PopoverButton
                        @mouseover="(e) => hoverPopover(e, open)"
                        @mouseleave="closePopover(close)"
                        class="flex items-center gap-x-1 text-sm/6 font-semibold  outline-none -mr-2"
                        :class="{
        textColorNotInverted: invert,
        textColorInvert: !invert
    }"
                    >
                        Solutions
                        <ChevronDownIcon
                            class="size-5 flex-none text-gray-400 pointer-events-none" aria-hidden="true"/>
                    </PopoverButton>

                    <transition enter-active-class="transition ease-out duration-200"
                                enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0"
                                leave-active-class="transition ease-in duration-150"
                                leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                        <PopoverPanel
                            @mouseover.prevent="popoverHover = true"
                            @mouseleave.prevent="closePopover(close)"
                            class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-[600px] overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                            <div class="p-4">
                                <div class="grid grid-cols-2">
                                    <div
                                        v-for="category in categories">
                                        <p class="text-sm/8 font-bold">
                                            {{ category.label }}
                                        </p>

                                        <div v-for="item in category.links" :key="item.name"
                                             class="group relative flex gap-x-6 rounded-lg px-4 py-1.5 text-sm/6 hover:bg-gray-50">
                                            <div
                                                class="mt-1 flex size-11 flex-none items-center justify-center rounded-lg bg-gray-200 group-hover:bg-primary">
                                                <MdiIcon :icon="item.icon"
                                                         class="size-6 text-gray-600 group-hover:text-white"
                                                         aria-hidden="true"/>
                                            </div>
                                            <div class="flex-auto">
                                                <Link
                                                    :href="item.href" class="block font-semibold text-gray-900"
                                                >
                                                    {{ item.name }}
                                                    <span class="absolute inset-0"/>
                                                </Link>
                                                <p class="mt-1 text-gray-600">{{ item.description }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                                <component
                                    :is="item.external ? 'a': Link"
                                    v-for="item in callsToAction" :key="item.name" :href="item.href"
                                    class="flex group items-center justify-center gap-x-2.5 p-3 text-sm/6 font-semibold hover:bg-gray-100"
                                    :class="{
        textColorNotInverted: invert,
        textColorInvert: !invert
    }"
                                >
                                    <component :is="item.icon"
                                               class="size-5 flex-none text-gray-400 group-hover:text-primary"
                                               aria-hidden="true"/>
                                    {{ item.name }}
                                </component>
                            </div>
                        </PopoverPanel>
                    </transition>
                </Popover>

                <Link class="text-sm/6 font-semibold hover:text-primary"
                      :class="{
        textColorNotInverted: invert,
       textColorInvert: !invert
    }"
                      v-for="item in regularLinks" :key="item.name" :href="item.href">
                    {{ item.name }}
                </Link>


                <Popover class="relative"
                         v-slot="{ open, close }">
                    <PopoverButton
                        @mouseover="(e) => hoverPopover(e, open)"
                        @mouseleave="closePopover(close)"
                        class="flex items-center gap-x-1 text-sm/6 font-semibold outline-none"
                        :class="{
        textColorNotInverted: invert,
        textColorInvert: !invert
    }"
                    >
                        Resources
                        <ChevronDownIcon class="size-5 flex-none text-gray-400 pointer-events-none" aria-hidden="true"/>
                    </PopoverButton>

                    <transition enter-active-class="transition ease-out duration-200"
                                enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0"
                                leave-active-class="transition ease-in duration-150"
                                leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                        <PopoverPanel
                            @mouseover.prevent="popoverHover = true"
                            @mouseleave.prevent="closePopover(close)"
                            class="absolute -left-8 top-full z-10 mt-3 w-56 rounded-xl bg-white p-2 shadow-lg ring-1 ring-gray-900/5">
                            <Link v-for="item in company" :key="item.name"
                                  :href="item.href"
                                  class="block rounded-lg px-3 py-2 text-sm/6 font-semibold text-gray-900 hover:bg-gray-50">
                                {{
                                    item.name
                                }}
                            </Link>
                        </PopoverPanel>
                    </transition>
                </Popover>
            </PopoverGroup>
            <div class="hidden lg:flex lg:flex-1 lg:justify-end">
                <div class="flex items-center gap-4">
                    <Button
                        variant="outline"
                        @click="() => router.visit(route('login'), { method: 'get' })"
                        class="text-sm/6 font-semibold text-gray-900">
                        Log in
                    </Button>

                    <StartTrialBusinessButton class="!scale-100 text-sm"/>
                </div>
            </div>
        </nav>

        <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
            <div class="fixed inset-0 z-10"/>
            <DialogPanel
                class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div class="flex items-center justify-between">
                    <Link :href="route('index')" class="-m-1.5 p-1.5">
                        <span class="sr-only">Greetmate</span>
                        <ApplicationLogo color="black"/>
                    </Link>
                    <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
                        <span class="sr-only">Close menu</span>
                        <XMarkIcon class="size-6" aria-hidden="true"/>
                    </button>
                </div>
                <div class="mt-6 flow-root">
                    <div class="-my-6 divide-y divide-gray-500/10">
                        <div class="space-y-2 py-6">
                            <!--                            <Disclosure as="div" class="-mx-3" v-slot="{ open }">-->
                            <!--                                <DisclosureButton-->
                            <!--                                    class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">-->
                            <!--                                    Solutions-->
                            <!--                                    <ChevronDownIcon :class="[open ? 'rotate-180' : '', 'size-5 flex-none']"-->
                            <!--                                                     aria-hidden="true"/>-->
                            <!--                                </DisclosureButton>-->
                            <!--                                <DisclosurePanel class="mt-2 space-y-2">-->
                            <!--                                    <DisclosureButton v-for="item in [...products, ...callsToAction]" :key="item.name"-->
                            <!--                                                      as="a" :href="item.href"-->
                            <!--                                                      class="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-900 hover:bg-gray-50">-->
                            <!--                                        {{ item.name }}-->
                            <!--                                    </DisclosureButton>-->
                            <!--                                </DisclosurePanel>-->
                            <!--                            </Disclosure>-->

                            <Disclosure
                                v-for="category in categories"
                                as="div" class="-mx-3" v-slot="{ open }">
                                <DisclosureButton
                                    class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
                                    {{ category.label }}
                                    <ChevronDownIcon :class="[open ? 'rotate-180' : '', 'size-5 flex-none']"
                                                     aria-hidden="true"/>
                                </DisclosureButton>
                                <DisclosurePanel class="mt-2 space-y-2">
                                    <DisclosureButton v-for="item in [...category.links]" :key="item.name"
                                                      as="a" :href="item.href"
                                                      class="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-900 hover:bg-gray-50">
                                        {{ item.name }}
                                    </DisclosureButton>
                                </DisclosurePanel>
                            </Disclosure>

                            <Link
                                class="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                                v-for="item in regularLinks" :key="item.name" :href="item.href">
                                {{ item.name }}
                            </Link>

                            <Disclosure as="div" class="-mx-3" v-slot="{ open }">
                                <DisclosureButton
                                    class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
                                    Resources
                                    <ChevronDownIcon :class="[open ? 'rotate-180' : '', 'size-5 flex-none']"
                                                     aria-hidden="true"/>
                                </DisclosureButton>
                                <DisclosurePanel class="mt-2 space-y-2">
                                    <DisclosureButton v-for="item in company" :key="item.name" as="a" :href="item.href"
                                                      class="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-900 hover:bg-gray-50">
                                        {{ item.name }}
                                    </DisclosureButton>
                                </DisclosurePanel>
                            </Disclosure>

                            <hr class="border-gray-500/10">

                            <Link
                                class="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                                v-for="item in callsToAction" :key="item.name" :href="item.href">
                                {{ item.name }}
                            </Link>

                        </div>
                        <div class="py-6">
                            <div class="flex flex-col gap-2 w-1/2 mx-auto">
                                <Button variant="outline"
                                        @click="() => router.visit(route('login'), { method: 'get' })"
                                        class="">
                                    Log in
                                </Button>

                                <StartTrialBusinessButton class="!scale-100 text-sm"/>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogPanel>
        </Dialog>
    </header>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import {Link} from "@inertiajs/vue3";
import {router} from "@inertiajs/vue3";

import {
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
} from '@headlessui/vue'
import {
    ArrowPathIcon,
    Bars3Icon,
    ChartPieIcon,
    CursorArrowRaysIcon,
    FingerPrintIcon,
    SquaresPlusIcon,
    XMarkIcon,
} from '@heroicons/vue/24/outline'
import {ChevronDownIcon, PhoneIcon, PlayCircleIcon} from '@heroicons/vue/20/solid'
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import StartTrialBusinessButton from "@/Components/Marketing/StartTrialBusinessButton.vue";
import {Button} from "@/Components/ui/button/index.js";
import MdiIcon from "@/Components/MdiIcon.vue";
import {
    mdiAccountWrench,
    mdiBriefcase,
    mdiCallMade, mdiCallReceived,
    mdiCash, mdiCurrencyUsd, mdiFaceAgent,
    mdiHome,
    mdiHospitalBuilding,
    mdiSolarPower, mdiToolboxOutline
} from "@mdi/js";

const industries = [
    {
        name: 'Financial Services', description: 'Qualify Callers and Collect Information',
        href: route('post', {slug: 'industries/financial-services'}),
        icon: mdiCash
    },
    {
        name: 'Health Care',
        description: 'Patient Intake and Appointment Scheduling',
        href: route('post', {slug: 'industries/health-care'}),
        icon: mdiHospitalBuilding
    },
    {
        name: 'Professional Services', description: 'Build Call Workflows that Convert',
        href: route('post', {slug: 'industries/professional-services'}),
        icon: mdiBriefcase
    },
    {
        name: 'Real Estate',
        description: 'Schedule Appointments and Qualify Callers',
        href: route('post', {slug: 'industries/real-estate'}),
        icon: mdiHome
    },
    {
        name: 'Home Services', description: 'Collect Information & Convert Leads',
        href: route('post', {slug: 'industries/home-services'}),
        icon: mdiAccountWrench
    },
    {
        name: 'Solar', description: 'Qualify Callers and Collect Information',
        href: route('post', {slug: 'industries/solar'}),
        icon: mdiSolarPower
    },
]

const useCases = [
    {
        name: 'AI Receptionist',
        description: 'Handle Incoming Calls',
        href: route('post', {slug: 'use-cases/ai-receptionist'}),
        icon: mdiFaceAgent
    },
    {
        name: 'AI Customer Service',
        description: 'Handle Customer Service Calls',
        href: route('post', {slug: 'use-cases/ai-customer-service'}),
        icon: mdiToolboxOutline
    },
    {
        name: 'AI Outbound Calls',
        description: 'Cold Calling & Follow Up Calls',
        href: route('post', {slug: 'use-cases/ai-outbound-calling'}),
        icon: mdiCurrencyUsd
    },
]

const categories = [
    {
        label: "Industries",
        links: industries
    },
    {
        label: "Use Cases",
        links: useCases
    },
]

const callsToAction = [
    {
        name: 'Book Demo',
        href: 'https://cal.com/team/greetmate-inc/30-minute-demo-call',
        external: true,
        icon: PlayCircleIcon
    },
    {
        name: 'Contact sales',
        href: route('post', {slug: 'sales'}),
        icon: PhoneIcon
    },
]
const company = [
    {name: 'Docs', href: route('post', {slug: 'coming-soon'})},
    {name: 'About us', href: route('post', {slug: 'blog/our-mission-behind-greetmate'})},
    {name: 'Careers', href: route('post', {slug: 'coming-soon'})},
    // {name: 'Support', href: '#'},
    // {name: 'Press', href: '#'},
    {name: 'Blog', href: route('blog')},
]

const regularLinks = [
    {name: 'Integrations', href: route('integrations')},
    // {name: 'Agency', href: route('pricing')},
    {name: 'Pricing', href: route('pricing')},
]

const mobileMenuOpen = ref(false)

const popoverHover = ref(false)
const popoverTimeout = ref(null)

const hoverPopover = (e, open) => {
    popoverHover.value = true
    try {
        if (!open) e.target.click()
    } catch (e) {
        console.error(e)
    }
}

const closePopover = (close) => {
    popoverHover.value = false
    if (popoverTimeout.value) clearTimeout(popoverHover.value)
    popoverTimeout.value = setTimeout(() => {
        if (!popoverHover.value) close()
    }, 300)
}

const invert = ref(false)
const textColorInvert = 'text-gray-900'
const textColorNotInverted = 'text-gray-900'
onMounted(() => {
    window.addEventListener('scroll', () => {
        invert.value = window.scrollY > 0
    })
})
</script>
